import { Button, Input, Modal, Radio, Select, Table, Upload, message, Image, Cascader, Switch } from 'antd'
import React, { useState, useContext, useEffect, useRef } from 'react'
import styles from './index.module.scss'
import { useIntl } from 'react-intl'
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FormOutlined } from '@ant-design/icons'
import MainContext from '../../stores/stores'
import orderApi from '@/api/orderApi';
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import accountApi from '@/api/accountApi';
import UploadCon from '@/components/UploadCon/UploadCon'
const CancelToken = axios.CancelToken
const controller = new AbortController();


let timeout: any = null
let timer: any = null



const Company: React.FC<any> = (props) => {
  const navigate = useNavigate()

  const {  } = props

  const { store, setStore } = useContext(MainContext)

  const { userInfo } = store

  const intl = useIntl()


  const [keyword, setKeyword] = useState<string>("")
  const [companyInfo, setCompanyInfo] = useState<any>({})
  const [pager, setPager] = useState<any>({
    page: 1,
    total: 0
  })
  const [pageSize, setPageSize] = useState<number>(10)

  const [loading, setLoading] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [otherLoading, setOtherLoading] = useState<boolean>(false)

  const [jobsList, setJobsList] = useState<any>([])

  const [list, setList] = useState<any[]>()



  const [open, setOpen] = useState<boolean>(false)
  const [jobOpen, setJobOpen] = useState<boolean>(false)
  const [assignmentOpen, setAssignmentOpen] = useState<boolean>(false)

  const [supplementOpen, setSupplementOpen] = useState<boolean>(false)

  const [activeItem, setActiveItem] = useState<any>({})
  const [allList, setAllList] = useState<any[]>([])
  const [searchOptions, setSearchOptions] = useState<any[]>([])
  let getCancel = useRef<any>(null)

  const [change_uid, set_change_uid] = useState<any>("")

  const [change, setChange] = useState<boolean>(false)
  
  useEffect(() => {
    getUserInfo()
  }, [])

  useEffect(() => {
    if(userInfo.repair_shop_id) {
      getList()
      getAllList()
    }
  },[pager, pageSize, keyword, userInfo.repair_shop_id])


  useEffect(() => {
    if(userInfo.repair_shop_id && companyInfo.category) {
      getJobsList()
    }
  },[userInfo.repair_shop_id, companyInfo.category])

  const getCompanyInfo = (repair_shop_id: string) => {
    orderApi.getCompanyInfo({
      repair_shop_id: repair_shop_id,
    }).then((res: any) => {
      if(res.code === 1) {
        setCompanyInfo(res.data)
      }
    })
  }
  const getUserInfo = () => {
    accountApi.getUserInfo().then((res: any) => {
      if(res.code === 1) {
        res.data.company = res.data.reg_company_name
        setStore({value: res.data, type: 'userInfo'})
        getCompanyInfo(res.data.repair_shop_id)
      }
    })
  }
  const getJobsList = () => {
    let params = {
      repair_shop_id: userInfo.repair_shop_id,
      category: companyInfo.category
    }
    orderApi.getJobsList(params).then((res: any) => {
      if(res.code === 1) {
        setJobsList(res.data)
      }
    })

  }
 
  const getList = () => {
    let params = {
      page: pager.page,
      page_size: pageSize,
      search: keyword,
      repair_shop_id: userInfo.repair_shop_id
    }
    setLoading(true)
    orderApi.getUserList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        pager.total = res.data.total
        setList(res.data.data)
      }
    })
  }

  const getAllList = () => {
    orderApi.getUserList({
      repair_shop_id: userInfo.repair_shop_id,
      page: 0
    }).then((res: any) => {
      if(res.code === 1) {
        let data = res.data && res.data.data ? res.data.data : []
        let _companyOption = data.map((item: any) => {
          return {
            label: `${item.phone}-${item.wechat_nick ? item.wechat_nick : ''}`,
            value: item.id
          }
        })
        setAllList(data)
        setSearchOptions(_companyOption)
      }
    })
  }
  const companySearch = (value: string) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    getCancel.current && getCancel.current('请求中断…')
    const rToken = new CancelToken((c: any) => getCancel.current = c)
    const getCompanyOption = () => {
      orderApi.getUserList({
        cancelToken: rToken,
        signal: controller.signal,
        search: value,
        repair_shop_id: userInfo.repair_shop_id,
        page: 0
      }).then((res: any) => {
        if(res.code === 1) {
          let _companyOption = res.data.data.map((item: any) => {
            return {
              label: item.phone + '-' + item.wechat_nick,
              value: item.id
            }
          })
          setSearchOptions(_companyOption)
        }
      })
    }
    if (value && value.length >= 3) {
      timeout = setTimeout(getCompanyOption, 300);
    } else {
      setSearchOptions([]);
    }
  }

  const operateChange = (status: any, item: any) => {
    let params = {
      repair_shop_id: userInfo.repair_shop_id,
      uid: item.id,
      verify_status: status
    }
    orderApi.updateVerifyStatus(params).then((res: any) => {
      if(res.code === 1) {
        getList()
        message.success(intl.formatMessage({ id: "操作成功！" }))
        
      }
    })
  }

  // 去认证
  const toShowAuth = () => {
    setSupplementOpen(true)
  }

  // 转让店长
  const assignment = () => {
    setAssignmentOpen(true)
    set_change_uid("")
  }
  const assignmentSubmit = () => {
    let index = allList.findIndex((ele) => ele.is_repair_shop_admin)
    let item = index > -1 ? allList[index] : {}
    let params = {
      repair_shop_id: userInfo.repair_shop_id,
      admin_uid: item.id,
      change_uid
    }
    setSubmitLoading(true)
    orderApi.assignment(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        message.success(intl.formatMessage({ id: "操作成功！" }))
        getUserInfo()
        setAssignmentOpen(false)
        navigate("/home")
      }
    })
  }

  const updateStatus = (item: any, index: number) => {
    let params = {
      uid: item.id,
      status: item.status ? 0 : 1,
      repair_shop_id: userInfo.repair_shop_id
    }
    item.loading = true
    setChange(change =>  !change)
    orderApi.updateStatus(params).then((res: any) => {
      item.loading = false
      setChange(change =>  !change)
      if(res.code === 1) {
        let _list = JSON.parse(JSON.stringify(list))
        _list[index].status = item.status ? 0 : 1
        setList(_list)
        message.success(intl.formatMessage({ id: "操作成功！" }))
      }
    })
  }


  const updateUserCompany = () => {
    let params = {
      uid: activeItem.id,
      old_repair_shop_id: userInfo.repair_shop_id,
      new_repair_shop_id: undefined,
			user_yc_id: activeItem.user_yc_id
    }
    setOtherLoading(true)
    orderApi.updateUserCompany(params).then((res: any) => {
      setOtherLoading(false)
      if(res.code === 1) {
        message.success(intl.formatMessage({ id: "操作成功！" }))
        getList()
        setOpen(false)
      }
    })
  }

  const submit = () => {
    let params: any = {
      phone: activeItem.phone,
      wechat_nick: activeItem.wechat_nick,
      company_name: companyInfo.name,
      job_name: activeItem.job_name,
      job_id: activeItem.job_id,
      status: activeItem.status,
      source: 'web',
      repair_shop_id: userInfo.repair_shop_id
    }
    if(activeItem.id) {
      params.uid = activeItem.id
      params.verify_status = activeItem.verify_status
    }
    setSubmitLoading(true)
    let api: any
    if(activeItem.id) {
      api = orderApi.updateUser(params)
    }else {
      api = orderApi.addUser(params)
    }
    api.then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        setOpen(false)
        getList()
        message.success(intl.formatMessage({ id: "操作成功！" }))
      }
    })
  }
  
  const columns: ColumnsType<any> = [
    {
      title: intl.formatMessage({ id: '序号' }),
      dataIndex: 'id',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        return (
          <div>
            {(pager.page - 1)*pageSize + index + 1}
          </div>
        )
      }
    },
    {
      title: intl.formatMessage({ id: '账号' }),
      dataIndex: 'phone',
    },
    {
      title: intl.formatMessage({ id: '昵称' }),
      dataIndex: 'wechat_nick',
      render: (text: any, record: any) => {
        return (
          <div>{record.wechat_nick || record.wechat_nick}</div>
        )
      }
    },
    {
      title: <div className='flex-c-c'>{intl.formatMessage({ id: '岗位' })}<i onClick={() => setJobOpen(true)} className='iconfont icon-bianji3 pointer ml5' style={{ fontSize: 14, color: '#0A6AEB' }} /></div>,
      dataIndex: 'job_name',
      align: 'center',
      render: (text: any, record: any) => {
        return (
          <>
            {
              text ? <div className='flex-c-c'>
                {
                  text === '店长' ? <i className='iconfont icon-dianchang mr5' /> : null
                }
                {
                  text === '采购' ? <i className='iconfont icon-caigou mr5' /> : null
                }
                {
                  text === '技师' ? <i className='iconfont icon-caigou mr5' /> : null
                }
                {
                  text === '前台' ? <i className='iconfont icon-qiantai mr5' /> : null
                }
                {
                  text !== '店长' && text !== '采购' && text !== '技师' && text !== '前台' ? <i className='iconfont icon-gangwei mr5' /> : null
                }
                {text}
              </div> : <div>-</div>
            }
          </>
        )
      }
    },
    {
      title: intl.formatMessage({ id: '注册时间' }),
      dataIndex: 'add_time',
    },
    {
      title: intl.formatMessage({ id: '账号状态' }),
      dataIndex: 'status',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        return (
          <div>
            <Switch checkedChildren={intl.formatMessage({ id: "启用" })} loading={record.loading ? true : false} unCheckedChildren={intl.formatMessage({ id: "禁用" })} disabled={record.is_repair_shop_admin ? true : false} checked={text ? true : false} onClick={() => updateStatus(record, index)} />
          </div>
        )
      }
    },
    {
      title: intl.formatMessage({ id: '审核状态' }),
      dataIndex: 'verify_status',
      align: 'center',
      render: (text: number, record: any, index: number) => {
        return (
          <>
            {
              text === 1 ? <>
                <span className={styles['agree']}>{intl.formatMessage({ id: '已通过' })}</span>
                {
                  record.is_repair_shop_admin ? null : <FormOutlined  rev={undefined} style={{fontSize: 16, color: '#096BED', marginLeft: 5}} onClick={() => operateChange(0, record)}/>
                }
              </> : null
            }
            {
              text === 2 ? <>
                <span className={styles['no-agree']}>{intl.formatMessage({ id: '未通过' })}</span>
                {
                  record.is_repair_shop_admin ? null : <FormOutlined  rev={undefined} style={{fontSize: 16, color: '#096BED', marginLeft: 5}} onClick={() => operateChange(0, record)}/>
                }
              </> : null
            }
            {
              text === 0 ? <Select className="user-list-select" style={{width: '100%'}} placeholder={intl.formatMessage({ id: "同意/拒绝" })} size="small" onChange={value => operateChange(value, record)} >
                <Select.Option value={1}>{intl.formatMessage({ id: '同意' })}</Select.Option>
                <Select.Option value={2}>{intl.formatMessage({ id: '拒绝' })}</Select.Option>
              </Select> : null
            }
          </>
        )
      }
    },
    {
      title: intl.formatMessage({ id: '操作' }),
      dataIndex: 'time',
      align: 'center',
      render: (text: any, record: any, index: number) => {
        return (
          <Button onClick={() => { setOpen(true); setActiveItem(record) }} type="primary" size="small" ghost  >{intl.formatMessage({ id: '编辑' })}</Button>
        )
      }
    },

  ]

  return (
    <div className={styles['company']}>
      <div className={styles['company-info']}>
        <div className='flex-s-c'>
          <img className={styles['logo']} src={companyInfo.photo_explanation && companyInfo.photo_explanation.logo && companyInfo.photo_explanation.logo[0] ? companyInfo.photo_explanation.logo[0] : "https://userimgs.007cst.com/20240516/845132246c9f28bae0faadf15c70d723.png"} alt="" />
          <div className='flex ml20'  style={{ flexDirection: 'column', alignItems: 'start', justifyContent: 'space-between', height: 60 }} >
            <div className='flex-s-c'>
              <span className={styles['name'] + ' mr5'}>{companyInfo.name}</span>
              {
                companyInfo.validate_status === 3 ? <span className={styles['tag']}>{intl.formatMessage({ id: '未认证' })}</span> : null
              }
              {
                companyInfo.validate_status === 2 ? <span className={styles['tag']}>{intl.formatMessage({ id: '认证失败' })}</span> : null
              }
              {
                companyInfo.validate_status === 0 ? <span className={styles['green']}>{intl.formatMessage({ id: '认证中' })}</span> : null
              }
              {
                companyInfo.validate_status === 1 ? <span className={styles['blue']}>{intl.formatMessage({ id: '已认证' })}</span> : null
              }
            </div>
            <p>{ companyInfo.province_city ? companyInfo.province_city : '' } { companyInfo.address ? companyInfo.address : '' }</p>
          </div>
        </div>
        {
          companyInfo.validate_status && companyInfo.validate_status === 1 ? <Button type="primary" style={{ minWidth: 110, height: 40 }} onClick={toShowAuth}>{intl.formatMessage({ id: '修改信息' })}</Button> : <Button type="primary" style={{ minWidth: 110, height: 40 }} onClick={toShowAuth}>{intl.formatMessage({ id: '去认证' })}</Button>
        }
        
      </div>
      <div className='mt20'>
        <div className='flex-b-c'>
          <Input.Search style={{ width: 300 }} placeholder={intl.formatMessage({ id: '输入账号/姓名搜索' })} allowClear onSearch={(value: any) => { setPager({...pager, page: 1}); setKeyword(value) }} />
          <div>
            {
              companyInfo.validate_status && companyInfo.validate_status === 1 && store.userInfo.is_repair_shop_admin ? <Button className='mr10' type="primary" ghost onClick={() => assignment()}>{intl.formatMessage({ id: '转让管理员' })}</Button> : null
            }
            {
              companyInfo.validate_status && companyInfo.validate_status === 1 && store.userInfo.is_repair_shop_admin ? <Button onClick={() => {setOpen(true); setActiveItem({ status: 1 })}} type="primary" ghost>{intl.formatMessage({ id: '添加人员' })}</Button> : null
            }
          </div>
        </div>
        <Table
          className='mt10'
          columns={columns}
          dataSource={list}
          loading={loading}
          pagination={{
            current: pager.page,
            total: pager.total,
            pageSize,
            showQuickJumper: true,
            showSizeChanger: true,
            // showTotal: (total:any) => `共 ${total} 条`,
            onChange: (page: any) => setPager({...pager, page: page}),
            onShowSizeChange: (pageSize: number) => setPageSize(pageSize)
          }}
        />
      </div>

      {/* 添加人员和编辑 */}
      <Modal
        title={activeItem.id ? intl.formatMessage({ id: '编辑' }) : intl.formatMessage({ id: '添加人员' })}
        open={open}
        onCancel={() => setOpen(false)}
        width={350}
        okButtonProps={{
          loading: submitLoading
        }}
        onOk={() => submit()}
      >
        <div className='mtb20'>
          <div className='flex-s-c'>
            <label htmlFor=""><span className='red'>*</span>{intl.formatMessage({ id: '账号' })}：</label>
            <Input value={activeItem.phone} onChange={(e: any) => setActiveItem({ ...activeItem, phone: e.target.value })} size="large" style={{ width: 220 }} placeholder={intl.formatMessage({ id: '输入手机号' })} />
          </div>
          <div className='flex-s-c mt10'>
            <label htmlFor=""><span className='red' style={{ opacity: 0 }}>*</span>{intl.formatMessage({ id: '姓名' })}：</label>
            <Input value={activeItem.wechat_nick} onChange={(e: any) => setActiveItem({ ...activeItem, wechat_nick: e.target.value })} size="large" style={{ width: 220 }} placeholder={intl.formatMessage({ id: '输入姓名' })} />
          </div>
          <div className='flex-s-c mt10'>
            <label htmlFor=""><span className='red' style={{ opacity: 0 }}>*</span>{intl.formatMessage({ id: '岗位' })}：</label>
            <Select
              size="large" 
              style={{ width: 220 }}
              placeholder={intl.formatMessage({ id: '选择岗位' })}
              options={
                jobsList.map((item: any) => {
                  return {
                    label: item.name,
                    value: item.name,
                    job_id: item.job_id
                  }
                })
              }
              value={activeItem.job_name}
              onChange={(vlaue: any, option: any) => setActiveItem({ ...activeItem, job_name: vlaue, job_id: option.job_id })}
            />
          </div>
          <div className='flex-s-c mt10'>
            <label htmlFor=""><span className='red' style={{ opacity: 0 }}>*</span>{intl.formatMessage({ id: '账号状态' })}：</label>
            <div className='left'>
              <div className={styles['radio-group']}>
                <div onClick={() => setActiveItem({...activeItem, status: 1})} className={styles['radio-button'] + ' ' + styles['left'] + ' ' + `${activeItem.status === 1 ?  styles['open'] : ''}`}>
                  {intl.formatMessage({ id: '启用' })}
                </div>
                <div onClick={() => setActiveItem({...activeItem, status: 0})} className={styles['radio-button'] + ' ' + styles['right'] + ' ' + `${activeItem.status === 0 ? styles['quit'] : ''}`}>
                  {intl.formatMessage({ id: '禁用' })}
                </div>
              </div>
            </div>
          </div>
          {
            activeItem.id && !activeItem.is_repair_shop_admin ? (
              <>
                <div className='flex-s-c mt10'>
                  <label htmlFor=""><span className='red' style={{ opacity: 0 }}>*</span>{intl.formatMessage({ id: '绑定关系' })}：</label>
                  <Button loading={otherLoading} type="primary" ghost style={{ width: 192, height: 32, borderColor: '#FF0000', color: '#FF0000' }} onClick={() => updateUserCompany()}>{intl.formatMessage({ id: '解绑' })}</Button>
                </div>
                <div className='flex-s-c mt5'>
                  <label htmlFor="" style={{ opacity: 0 }}><span className='red' >*</span>{intl.formatMessage({ id: '绑定关系' })}：</label>
                  <span style={{ color: '#888888', fontSize: 14 }}>*{intl.formatMessage({ id: '解绑后，将不再是公司成员！' })}</span>
                </div>
              </>
            ): null
          }
        </div>
      </Modal>

      {/* 职位  */}
      {
        jobOpen ? (
          <CompanyJobs
            repair_shop_id={userInfo.repair_shop_id}
            onCancel={() => setJobOpen(false)}
            jobsList={JSON.parse(JSON.stringify(jobsList))}
            onRefush={() => getJobsList()}
          />
        ): null
      }
      

      <Modal
        title={intl.formatMessage({ id: '转让管理员' })}
        open={assignmentOpen}
        onCancel={() => setAssignmentOpen(false)}
        width={320}
        okButtonProps={{
          loading: submitLoading
        }}
        onOk={assignmentSubmit}
      >
        <div className='mtb20'>
          <div className='flex-b-c mt10'>
            <label htmlFor="" style={{ width: 60 }}><span className='red'>*</span>{intl.formatMessage({ id: '店长' })}：</label>
            <Select 
              size="large"
              showSearch
              placeholder="选择账号"
              style={{ width: 220 }}
              options={searchOptions}
              filterOption={(input: string, option?: { label: string; value: string }) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              onSearch={companySearch}
              value={change_uid}
              onChange={(value: any) => set_change_uid(value)}
            />
          </div>
          <div className='flex-s-c'>
            <label htmlFor="" style={{ opacity: 0, width: 60 }}><span className='red' >*</span>{intl.formatMessage({ id: '绑定关系' })}：</label>
            <span style={{ color: '#888888', fontSize: 14 }}>*{intl.formatMessage({ id: '转让后，该账号将成为店长！' })}</span>
          </div>
        </div>
      </Modal>

      

      {
        supplementOpen ? (
          <Supplement
            companyInfo={companyInfo}
            onCancel={() => setSupplementOpen(false)}
            onConfirm={() => {getUserInfo()}}
          />
        ): null
      }
    </div>
  )
}

export default Company




const CompanyJobs: React.FC<any> = (props) => {
  const intl = useIntl()
  const { onCancel = ()=>{}, jobsList, repair_shop_id, onRefush = ()=>{} } = props
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const [list, setList] = useState<any>(jobsList || [])

  const addItem = () => {
    let _list = JSON.parse(JSON.stringify(list))
    _list.push({
      name: '',
      is_add: 0
    })
    setList(_list)
  }
  const setItemValue = (index: number, value: any) => {
    let _list = JSON.parse(JSON.stringify(list))
    _list[index].name = value
    setList(_list)
  } 
  const submit = () => {
    let params = {
      repair_shop_id,
      jobs: list.filter((item: any) => item.name)
    }
    setSubmitLoading(true)
    orderApi.updateJobs(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        onCancel()
        message.success(intl.formatMessage({ id: "操作成功！" }))
        onRefush()
      }
    })
  }

  return (
    <Modal
      title={<div className='flex-s-c'>{intl.formatMessage({ id: '编辑岗位' })}<i className='iconfont icon-Union4 pointer ml5' style={{ color: '#096BED' }} onClick={() => addItem()} /></div>}
      open={true}
      onCancel={() => onCancel()}
      width={360}
      okButtonProps={{
        loading: submitLoading
      }}
      onOk={submit}
    >
      <div className='mtb20'>
        {
          list.map((item: any, index: number) => {
            return (
              <div className='flex-s-c mt10' key={index} >
                {
                  item.is_add ? (
                    <i 
                      className='iconfont icon-shanchu red mr10' 
                      style={{ opacity: .4 }}
                    />
                  ): (
                    <i 
                      className='iconfont icon-shanchu red mr10' 
                      onClick={() => {
                        let _jobsList = JSON.parse(JSON.stringify(jobsList))
                        _jobsList.splice(index, 1)
                        setList(_jobsList)
                      }}
                    />
                  )
                }
                <Input placeholder={intl.formatMessage({ id: '输入岗位名称' })} disabled={item.is_add} value={item.name} onChange={(e: any) => setItemValue(index, e.target.value)} />
              </div>
            )
          })
        }
      </div>
    </Modal>
  )
}



// Supplement

const Supplement: React.FC<any> = (props) => {
  const navigate = useNavigate()
  const { store, setStore } = useContext(MainContext)
  const { areaOptions, userInfo } = store
  const { onCancel, onConfirm } = props
  const intl = useIntl()
  let companyInfo: any = props.companyInfo ? props.companyInfo : {}
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [companyTypeList, setCompanyTypeList] = useState<any>([])
  const [activeItem, setActiveItem] = useState<any>({})
  const [data, setData] = useState<any>({

  })
  const [areaName, setAreaName] = useState<any[]>([])
  const [isHas, setIsHas] = useState<boolean>(false)
  const [newCompanyInfo, setNewCompanyInfo] = useState<any>({})
  const [isCheckCompany, setIsCheckCompany] = useState<boolean>(false)
  const [licenseLoading, setLicenseLoading] = useState<boolean>(false)

  useEffect(() => {
    let photo_explanation = companyInfo.photo_explanation ? companyInfo.photo_explanation : {}
    let licenseList = photo_explanation.license ? photo_explanation.license : []
    let cardList = photo_explanation.card ? photo_explanation.card : []
    let storeList = photo_explanation.store ? photo_explanation.store : []
    let logoList = photo_explanation.logo ? photo_explanation.logo : []
    let license = licenseList[0] ? licenseList[0] : ''
    let card = cardList[0] ? cardList[0] : ''
    let storeImg = storeList[0] ? storeList[0] : ''
    let logo = logoList[0] ? logoList[0] : ''
    if(companyInfo.validate_status && companyInfo.validate_status === 1 && store.userInfo && store.userInfo.verify_status && store.userInfo.verify_status === 1) {
      setIsHas(false)
      setIsCheckCompany(true)
    }else if(companyInfo.validate_status && companyInfo.validate_status === 1 && store.userInfo && store.userInfo.verify_status !== 1) {
      setIsHas(true)
      setIsCheckCompany(true)
      setNewCompanyInfo({
        repair_shop_id: companyInfo.repair_shop_id,
        name: companyInfo.name,
        merchant_id: companyInfo.merchant_id
      })
    }
    if(companyInfo.province_city) {
      let arr = companyInfo.province_city.split("/")
      let province = arr[0] ? arr[0] : ''
      let city = arr[1] ? arr[1] : ''
      let area = arr[2] ? arr[2] : ''
      if(arr.length > 0) {
        setAreaName([{name: province, label: province}, {name: city, label: city}, {name: area, label: area}])
      }
    }
    setData({
      license,
      card,
      store: storeImg,
      logo
    })
    setActiveItem({
      ...companyInfo,
      wechat_nick: store.userInfo.wechat_nick || "",
			name: companyInfo.validate_status && companyInfo.validate_status === 3 ? '' : companyInfo.name
    })
  }, [companyInfo])

  useEffect(() => {
    getcategoryList()
  },[])

  const getcategoryList = () => {
    orderApi.getCompanyType().then((res: any) => {
      if(res.code === 1) {
        let _data = res.data.map((item: any) => {
          return {
            value: item,
            label: item
          }
        })
        setCompanyTypeList(_data)
      }
    })
  }

  const checkCompany = (item: any) => {
    orderApi.checkCompany({
      name: item.name,
			repair_shop_id: userInfo.repair_shop_id
    }).then((res: any) => {
      if(res.code === 1) {
        let _data = res.data
        setIsCheckCompany(true)
        setNewCompanyInfo(_data)
        if(JSON.stringify(_data) === '{}' || !_data) {
          setIsHas(false)
        }else {
          setIsHas(true)
        }
      }
    })
  }

  const identifyLicense = (_data: any) => {
    /* if(data.company_info) {
      let item: any = {...activeItem, tax_number: data.company_info.company_credit_no || undefined, name: data.company_info.company_name || undefined}
      setActiveItem({...activeItem, tax_number: data.company_info.company_credit_no || undefined, name: data.company_info.company_name || undefined})
      checkCompany(item)
    }
    setData({...data, license: data.img_url}) */
    let request_id = _data.request_id
    getIdentifyInfo(request_id) 
  }
  const getIdentifyInfo = (request_id: string) => {
    clearTimeout(timer)
    timer = null
    orderApi.getIdentifyInfo({
      request_id
    }).then((res: any) => {
      if(res.code === 1) {
        let _data = res.data
        if(_data.status === 2) {
          clearTimeout(timer)
          timer = null
          setLicenseLoading(false)
          if(!(companyInfo.validate_status && companyInfo.validate_status === 1)) {
            let item: any = {...activeItem, tax_number: _data.company_credit_no || undefined, name: _data.company_name || undefined}
            setActiveItem(item)
            checkCompany(item)
          }
          setData({...data, license: _data.img_url})
        }else if(_data.status === 1){
          setData({...data, license: _data.img_url})
          if(!timer) {
            timer = setTimeout(() => {
              getIdentifyInfo(request_id)
            }, 1000)
          }
          
        }else if(_data.status === 3) {
          setData({...data, license: _data.img_url})
          clearTimeout(timer)
          timer = null
          setLicenseLoading(false)
        }
      }
    })
  }

  const submit = () => {
    if(!activeItem.wechat_nick) {
      message.warning(intl.formatMessage({ id: '请输入申请人姓名' }))
      return false
    }
    if(!activeItem.name) {
      message.warning(intl.formatMessage({ id: '请输入公司名称' }))
      return false
    }
    if(!isCheckCompany) {
      orderApi.checkCompany({
        name: activeItem.name,
				repair_shop_id: userInfo.repair_shop_id
      }).then((res: any) => {
        if(res.code === 1) {
          let _data = res.data
          setIsCheckCompany(true)
          setNewCompanyInfo(_data)
          if(JSON.stringify(_data) === '{}' || !_data) {
            setIsHas(false)
            submitFun(false)
          }else {
            setIsHas(true)
            submitFun(true)
          }
        }
      })
    }else {
      submitFun(isHas)
    }
  }
  const submitFun = (_isHas: boolean) => {
    if(_isHas) {
      let params = {
        uid: userInfo.id,
        old_repair_shop_id: userInfo.repair_shop_id,
        new_repair_shop_id: newCompanyInfo.repair_shop_id,
        wechat_nick: activeItem.wechat_nick
      }
      setSubmitLoading(true)
      orderApi.updateUserCompany(params).then((res: any) => {
        setSubmitLoading(false)
        if(res.code === 1) {
          if(res.code === 1) {
            onConfirm()
            onCancel()
            message.success(intl.formatMessage({ id: "操作成功！" }))
            navigate("/mine/setting", {replace: true})
          }
        }
      })
    }else {
      let area = areaName.map((item: any) => item.name)
      let params = {
        ...activeItem,
        photo_explanation: {
          license: data.license ? [data.license] : [],
          card: data.card ? [data.card] : [],
          store: data.store ? [data.store] : [],
          logo: data.logo ? [data.logo] : []
        },
        province_city: area.join("/")
      }
      setSubmitLoading(true)
      orderApi.updateCompanyInfo(params).then((res: any) => {
        setSubmitLoading(false)
        if(res.code === 1) {
          onConfirm()
          onCancel()
          message.success(intl.formatMessage({ id: "操作成功！" }))
        }
      })
    }
  }

  return (
    <Modal
      wrapClassName='nopadding-model'
      open={true}
      onCancel={() => onCancel()}
      onOk={submit}
      footer={null}
      width={520}
      okButtonProps={{
        loading: submitLoading
      }}
    >
      <div className={styles["supplement"]}>
        {
          companyInfo.validate_status && companyInfo.validate_status === 1 ? <div className={styles['s-title']}>
            {intl.formatMessage({ id: '修改公司信息' })}
          </div> : <div className={styles['header']}>
            <div>
              <div className={styles['b1']}>{intl.formatMessage({ id: '请认证公司，获取查询次数！' })}</div>
              <p className={styles['b2']}>{intl.formatMessage({ id: '认证后可获得更多，商品查询次数' })}</p>
            </div>
            <img src='https://userimgs.007cst.com/20240516/bff235ce8f5fc2cba704e892582f605b.png' />
          </div>
        }
        
        <div className={styles['body']}>
          <div className='flex-s-c'>
            <label style={{ width: 80 }}></label>
            <div className='flex-1'>
              <div className='flex-b-c'>
                <UploadCon
                  img={data.license}
                  disabled={companyInfo.validate_status && companyInfo.validate_status === 1 && data.license ? true : false}
                  loading={licenseLoading}
                  setLoading={setLicenseLoading}
                  setImg={(value: string) => setData({...data, license: value})}
                  tip="上传营业执照"
                  name='file'
                  action='/v1/api/cstnl/license/img/identify/v2'
                  getData={(_data: any) => identifyLicense(_data)}
                />
                <div style={{ color: '#888888' }}>{intl.formatMessage({ id: '或' })}</div>
                <UploadCon
                  img={data.card}
                  disabled={companyInfo.validate_status && companyInfo.validate_status === 1 && data.card ? true : false}
                  setImg={(value: string) => setData({...data, card: value})}
                  tip="上传公司名片"
                />
                {/* <img className={styles['example']} src='https://userimgs.007cst.com/20240516/0b70bf63b1513599ac88f8963156d57e.png' />  */}
              </div>
              <p className={styles['license-tip']}>{intl.formatMessage({ id: '上传营业执照，可以自动识别并填充相关信息！' })}</p>
            </div>
            
          </div>
          <div className="flex-s-c mt15">
            <label><span className='red'>*</span>{intl.formatMessage({ id: '申请人姓名' })}：</label>
            <Input value={activeItem.wechat_nick}  onChange={(e: any) => {setActiveItem({...activeItem, wechat_nick: e.target.value})}} style={{ width: 370 }} size="large" placeholder={intl.formatMessage({ id: '输入申请人姓名' })}  />
          </div>
          <div className="flex-s-c mt15">
            <label><span className='red'>*</span>{intl.formatMessage({ id: '公司名称' })}：</label>
            <Input value={activeItem.name} disabled={companyInfo.validate_status && companyInfo.validate_status === 1 ? true : false} onChange={(e: any) => {setActiveItem({...activeItem, name: e.target.value}); checkCompany({...activeItem, name: e.target.value})}} style={{ width: 370 }} size="large" placeholder={intl.formatMessage({ id: '输入公司名称' })} onBlur={() => checkCompany(activeItem)} />
          </div>
          {
            isHas ? <div className="flex-s-c mt5">
              <label></label>
              <p className='red' style={{ fontSize: 12 }}>{intl.formatMessage({ id: '该公司已认证，您将自动申请加入此公司' })}</p>
            </div> : null
          }
          <div className="flex-s-c mt15">
            <label>{intl.formatMessage({ id: '公司税号' })}：</label>
            <Input value={activeItem.tax_number} onChange={(e: any) => setActiveItem({ ...activeItem, tax_number: e.target.value })} disabled={isHas || (companyInfo.validate_status && companyInfo.validate_status === 1) ? true : false} style={{ width: 370 }} size="large" placeholder={intl.formatMessage({ id: '输入税号' })} />
          </div>
          <div className="flex-s-c mt15">
            <label>{intl.formatMessage({ id: '公司注册城市' })}：</label>
            <Cascader
              placeholder={intl.formatMessage({ id: "选择省/市/区" })}
              style={{ width: 370 }}
              size="large"
              disabled={isHas}
              fieldNames={{ label: 'name', value: 'name' }}
              options={areaOptions}
              value={areaName.map((item: any) => item.name)}
              onChange={(value: any, selectedOptions: any) => setAreaName(selectedOptions)}
            />
          </div>
          <div className="flex-s-c mt15">
            <label>{intl.formatMessage({ id: '公司详细地址' })}：</label>
            <Input value={activeItem.address} disabled={isHas} onChange={(e: any) => setActiveItem({ ...activeItem, address: e.target.value })} style={{ width: 370 }} size="large" placeholder={intl.formatMessage({ id: '输入街道、门牌号、写字楼、商圈' })} />
          </div>
          <div className="flex-s-c mt15">
            <label>{intl.formatMessage({ id: '公司类型' })}：</label>
            <Select
              value={activeItem.category} 
              onChange={(value: string) => setActiveItem({ ...activeItem, category: value })}
              style={{ width: 370 }}
              disabled={isHas}
              size="large"
              options={companyTypeList}
              placeholder={intl.formatMessage({ id: '选择类型' })}
            />
          </div>
          <div className='flex mt15'>
            <label>{intl.formatMessage({ id: '其它照片' })}：</label>
            <div className='flex-1 flex-s-c'>
              {/* <UploadCon
                img={data.card}
                setImg={(value: string) => setData({...data, card: value})}
                tip="公司名片"
                width={80}
                height={80}
                className="mr20"
              /> */}
              <UploadCon
                disabled={isHas}
                img={data.store}
                setImg={(value: string) => setData({...data, store: value})}
                tip="门店照片"
                width={80}
                height={80}
                className="mr20"
              />
              <UploadCon
                disabled={isHas}
                img={data.logo}
                setImg={(value: string) => setData({...data, logo: value})}
                tip="Logo图片"
                width={80}
                height={80}
              />
            </div>
          </div>
          <div className='mt30 right'>
            <Button style={{ width: 370 }} size="large" type="primary" loading={submitLoading} onClick={() => submit()}>{(companyInfo.validate_status && companyInfo.validate_status === 1) || !companyInfo.validate_status ? intl.formatMessage({ id: '修改' }) : intl.formatMessage({ id: '提交申请' })}</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}


// UploadCon






export {
  Supplement
}



