import React, { useState, useContext, useEffect } from "react";
import styles from './index.module.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import { useIntl } from 'react-intl'
import MainContext from '../../stores/stores'

const Mine: React.FC<any> = (props) => {
  const { store, setStore } = useContext(MainContext)
	const { isCst, distributorInfo } = store
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const intl = useIntl()
  const [selectedKeys, setSelectedKeys] = useState<string[]>([pathname])
  useEffect(() => {
    setSelectedKeys([pathname])
  }, [pathname])
  
	
	const istest: number = window.location.host.includes("webtest") || window.location.host.includes("localhost:300") ? 1 : 0
	
  let companyItems = store.userInfo && store.userInfo.is_repair_shop_admin ? [
    {
      label: intl.formatMessage({ id: '公司管理' }),
      key: '/mine/company'
    },
  ] : []
	
	let invitationItem  = distributorInfo.status ? [
		{
		  label: intl.formatMessage({ id: '邀请码' }),
		  key: '/mine/invitation'
		}
	]: []

  const items: any = store.userInfo && store.userInfo.verify_status && store.userInfo.verify_status === 1 ? [
    {
      label: intl.formatMessage({ id: '订单列表' }),
      key: '/mine/order'
    },
		{
		  label: intl.formatMessage({ id: '退款/售后' }),
		  key: '/mine/aftersales',
      cstnot: istest ? 0 : 1
    },
    {
      label: intl.formatMessage({ id: '对账单' }),
      key: '/mine/reconciliation',
			cstnot: istest ? 0 : 1
    },
    {
      label: intl.formatMessage({ id: '应付单' }),
      key: '/mine/copewith',
			cstnot: istest ? 0 : 1
    },
    {
      label: intl.formatMessage({ id: '地址管理' }),
      key: '/mine/address'
    },
    {
      label: intl.formatMessage({ id: '发票抬头管理' }),
      key: '/mine/invoice',
			cstnot: istest ? 0 : 1
    },
    ...companyItems,
    {
      label: intl.formatMessage({ id: '设置' }),
      key: '/mine/setting'
    },
		...invitationItem,
  ] : [
    {
      label: intl.formatMessage({ id: '地址管理' }),
      key: '/mine/address'
    },
    {
      label: intl.formatMessage({ id: '发票抬头管理' }),
      key: '/mine/invoice',
			cstnot: istest ? 0 : 1
    },
    ...companyItems,
    {
      label: intl.formatMessage({ id: '设置' }),
      key: '/mine/setting'
    },
		...invitationItem
  ]

  const goTo = (key: string) => {
    navigate(key)
    setSelectedKeys([key])
  }
  
  return (
    <div className={styles['mine']}>
      <div className="flex">
        <Menu
          style={{ width: 160, marginRight: 10 }}
          selectedKeys={selectedKeys}
          items={items.filter((it: any) => !isCst || !it.cstnot)}
          onClick={(e: any) => goTo(e.key)}
        />
        <div className="flex-1" style={{ background: '#fff', minHeight: 'calc(100vh - 290px)' }}>
          <Outlet/>
        </div>
      </div>
      
      
    </div>
  )
}

export default Mine